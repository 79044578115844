<template>
  <div id="tutorial-complete-men">
    <AppContainer :useBackground="true">
      <div class="tutorial-complete f-maru">
        <div class="text-left" @click="$emit('back-tutorial')">
          <img
            class="btn-back"
            :src="require('@/assets/image/arrow-back.svg')"
            alt=""
          />
        </div>
        <div class="tutorial-complete__content">
          <div class="tutorial-complete__content__notification">
            <div class="tutorial-complete__content__notification--text">
              <p>プロフィールの編集が</p>
              <p>完了しました！</p>
              <p>詳細を設定すると</p>
              <p>マッチング率アップ！！</p>
              <div class="tutorial-complete__content__notification--button">
                <div
                  class="position-relative d-flex align-items-center justify-content-center f-maru"
                  @click="nextTutorial()"
                >
                  <input type="button" value="詳細編集へ進む" />
                  <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
                </div>
                <div
                  class="
                    position-relative
                    return-home
                    d-flex
                    align-items-center
                    justify-content-center
                    f-maru
                  "
                  @click="returnHome()"
                >
                  <input type="button" value="HOME" />
                  <img src="@/assets/image/icon-next.svg" class="icon" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
export default {
  name: "TutorialEditProfileCompleteMen",
  methods: {
    nextTutorial() {
      this.$emit("tutorialEditComplete");
    },
    returnHome() {
      localStorage.removeItem("step");
      document.scrollingElement.style.position = "static";
      document.scrollingElement.style.overflow = "auto";
      document.scrollingElement.style["overscroll-behavior"] = "auto";
      this.$router.push({
        name: "CastHome",
        params: {
          showPopup: "show"
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/tutorialStep10.scss";
@import "@/assets/scss/_fontFamily.scss";
#tutorial-complete-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100%;
}
</style>
