<template>
  <div id="bacsic-info-men" @click="blur">
    <AppContainer :useBackground="true">
      <div class="tutorial-basic-info f-maru">
        <!-- <div class="tutorial-notification"></div> -->
        <div class="content">
          <div class="tutorial-notification__div card">
            <div
              class="text-left"
              @click="$emit('back-tutorial')"
              v-if="auth.login_type != 'LINE'"
            >
              <!--              <img-->
              <!--                class="btn-back"-->
              <!--                :src="require('@/assets/image/arrow-back.svg')"-->
              <!--                alt=""-->
              <!--              />-->
            </div>
            <div class="process-bar-info">
              <process-bar-profile :processStep="processStep" />
            </div>
            <p class="text-address f-w6">
              プロフィールを登録しましょう
            </p>
            <!--            <p class="text-product">残り４項目です！</p>-->
            <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
              <form @submit.prevent="onSubmit" class="form">
                <ValidationProvider>
                  <div class="tutorial-welcome">
                    <div
                      class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                    >
                      <div class="content--checkbox f-maru">
                        <div
                          class="d-flex position-relative align-items-center top age-acc"
                        >
                          <div
                            class="checkbox d-flex justify-content-center align-items-center"
                            id="checkbox"
                            :class="checkedAge ? 'active' : ''"
                            @click="checkedAge = !checkedAge"
                          >
                            <b-icon icon="check" v-if="checkedAge" />
                          </div>
                          <span class="text">
                            <span class="text--blue">20歳以上</span>
                            です。
                          </span>
                        </div>
                        <div
                          class="d-flex position-relative align-items-center"
                        >
                          <div
                            class="checkbox d-flex justify-content-center align-items-center checkbox-law"
                            id="checkbox"
                            :class="checkedLaw ? 'active' : ''"
                            @click="checkedLaw = !checkedLaw"
                          >
                            <b-icon icon="check" v-if="checkedLaw" />
                          </div>
                          <span class="text text--bottom">
                            <span class="text--blue text-law"
                              ><span class="underline" @click="showLaw()"
                                >利用規約</span
                              >、<span class="underline" @click="nextPolicy()"
                                >プライバシーポリシー</span
                              >
                            </span>
                            <span class="text-black">に同意する</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="ニックネーム"
                  rules="required|min:1|max:8"
                  v-slot="{ errors }"
                >
                  <div
                    class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                  >
                    <p class="label f-maru">
                      <span>ニックネーム</span>
                      <span class="requied-cl"> ※必須</span>
                    </p>
                    <div class="input-option">
                      <input
                        type="text"
                        class="w-100"
                        v-model="nickname"
                        @blur="handleBlurNickname()"
                        @keyup="changeNickName"
                      />
                    </div>
                    <span class="error text-left">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div
                  class="text-left justify-content-center position-relative option"
                >
                  <div
                    v-for="(select, index) in select"
                    :key="index"
                    class="position-relative point"
                    :class="select.class"
                  >
                    <p
                      class="label f-maru"
                      :class="index == 1 ? 'label--bottom' : ''"
                    >
                      <span>{{ select.name }}</span>
                      <span class="requied-cl"> ※必須</span>
                    </p>
                    <div
                      class="focus-div"
                      :id="index == 0 ? 'best-score' : 'avg-score'"
                    >
                      <div
                        class="
                      position-relative
                      d-flex
                      align-items-center
                      button-option
                    "
                        :class="select.isShow ? 'active' : ''"
                        @click="showOptionPoint(index, select.name)"
                      >
                        <div
                          type=""
                          class="w-100 input d-flex align-items-center"
                        >
                          {{ select.value }}
                        </div>
                      </div>
                      <transition name="slide">
                        <div class="options" v-if="select.isShow">
                          <p
                            :class="select.name"
                            v-for="(option, indexOption) in select.options"
                            :key="indexOption"
                            @click="
                              selectOptionPoint(
                                index,
                                select.options[indexOption]
                              )
                            "
                          >
                            {{ select.options[indexOption] }}
                          </p>
                        </div>
                      </transition>
                    </div>
                    <span class="error">{{ select.error }}</span>
                    <!-- </ValidationProvider> -->
                  </div>
                </div>
                <div
                  class="edit text-left justify-content-center option row box-date"
                >
                  <div
                    class="position-relative col-4 date-padding"
                    v-for="(text, index) in text"
                    :key="index"
                    :class="{
                      disabled:
                        (index == 1 && !showMonth) || (index == 2 && !showDay)
                    }"
                  >
                    <p class="text-year f-maru">{{ text.name }}</p>
                    <ValidationProvider :name="text.name" rules="required">
                      <div class="focus-div" :id="text.name">
                        <div
                          class="
                            position-relative
                            d-flex
                            align-items-center
                            button-option
                          "
                          :class="text.isShow ? 'active' : ''"
                          @click="showOption(index, text.name)"
                        >
                          <div class="d-flex align-items-center w-100 input">
                            {{ text.value }}
                          </div>
                        </div>
                        <transition name="slide">
                          <div class="options" v-if="text.isShow">
                            <p
                              :class="text.name"
                              v-for="(option, indexOption) in text.options"
                              :key="indexOption"
                              @click="
                                selectOption(
                                  index,
                                  text.options[indexOption],
                                  indexOption
                                )
                              "
                            >
                              {{ text.options[indexOption] }}
                            </p>
                          </div>
                        </transition>
                      </div>
                      <span class="error text-left">{{ text.error }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <!--                <div class="text-left justify-content-center option text-btn">-->
                <!--                  <p class="warning warning-top text-left mb-0 date-warning">-->
                <!--                    年齢のみ表示されます。-->
                <!--                  </p>-->
                <!--                </div>-->
                <div
                  class="only-show-profile-text text-left justify-content-center option text-btn suggest-year"
                >
                  <img
                    src="@/assets/image/icon_profile/profile-note-icon.png"
                    alt=""
                  />
                  <p>
                    年齢のみ表示されます。
                  </p>
                </div>
                <div class="basic-info-acc f-w6">
                  <p>基本情報</p>
                </div>
                <ValidationProvider
                  name="電話番号"
                  rules="required|regex:^\d{3}-\d{4}-\d{4}$"
                  v-slot="{ errors }"
                >
                  <div
                    class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                  >
                    <p class="label f-maru">
                      <span>電話番号</span>
                      <span class="requied-cl"> ※必須</span>
                    </p>
                    <div class="input-option">
                      <input
                        class="w-100 input-phone-number"
                        type="tel"
                        v-model="phone"
                        v-on:keydown="checkPhone()"
                        v-on:blur="handleBlur()"
                      />
                    </div>
                    <!--                    <div class="m-auto text-btn">-->
                    <!--                      <p class="warning warning-top text-left mb-0">-->
                    <!--                        プロフィールには公開されません。-->
                    <!--                      </p>-->
                    <!--                      <p class="warning warning-top text-left mb-0">-->
                    <!--                        数字は半角で、ハイフン（-）は無しで記載してください。-->
                    <!--                      </p>-->
                    <!--                    </div>-->
                    <div class="text-left m-auto text-btn">
                      <span class="warning">{{ errors[0] }}</span>
                    </div>
                    <div class="only-show-profile-text">
                      <img
                        src="@/assets/image/icon_profile/profile-note-icon.png"
                        alt=""
                      />
                      <p>
                        プロフィールには公開されません。
                      </p>
                    </div>
                    <div class="only-show-profile-text">
                      <img
                        src="@/assets/image/icon_profile/profile-note-icon.png"
                        alt=""
                      />
                      <p>
                        数字は半角で、ハイフン（-）は無しで記載してください。
                      </p>
                    </div>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="郵便番号"
                  rules="required|regex:^\d{3}-\d{4}$"
                  v-slot="{ errors }"
                >
                  <div
                    class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                      num-address
                    "
                  >
                    <p class="label f-maru lb-address">
                      <span>郵便番号</span>
                      <span class="requied-cl"> ※必須</span>
                    </p>
                    <div
                      class="
                        d-flex
                        text-left
                        justify-content-center
                        position-relative
                        option
                      "
                    >
                      <div class="input-option">
                        <input
                          type="text"
                          class="post-code"
                          v-model="postCode"
                          v-on:keydown="checkPostCode()"
                          v-on:blur="handleBlur()"
                        />
                      </div>
                      <div
                        class="
                          mt-0
                          button-post-code
                          f-maru
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                        @click="fillPrefecture"
                      >
                        住所検索
                      </div>
                    </div>
                    <p class="error text-left">{{ errors[0] }}</p>
                    <div class="only-show-profile-text">
                      <img
                        src="@/assets/image/icon_profile/profile-note-icon.png"
                        alt=""
                      />
                      <p>プロフィールには都道府県しか表示されません</p>
                    </div>
                  </div>
                </ValidationProvider>
                <div class="text-left position-relative option box-address">
                  <div class="province">
                    <ValidationProvider
                      name="都道府県"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div
                        class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                      >
                        <div>
                          <p class="label f-maru">
                            <span>
                              都道府県
                            </span>
                            <span class="requied-cl"> ※必須</span>
                          </p>
                          <div class="input-option">
                            <input type="text" v-model="prefecture" readonly />
                          </div>
                          <span class="error text-left">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="district">
                    <ValidationProvider
                      name="市区町村"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div
                        class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                      >
                        <div>
                          <p class="label f-maru">
                            <span>市区町村</span>
                            <span class="requied-cl"> ※必須</span>
                          </p>
                          <div class="input-option">
                            <input type="text" v-model="residence" readonly />
                          </div>
                          <span class="error text-left">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <ValidationProvider name="町域" rules="" v-slot="{ errors }">
                  <div
                    class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                    hidden
                  >
                    <div>
                      <p class="label f-maru">
                        <span>町域</span>
                        <span class="requied-cl"> ※必須</span>
                      </p>
                      <div class="input-option">
                        <input type="text" v-model="area" readonly />
                      </div>
                      <span class="error text-left">{{ errors[0] }}</span>
                    </div>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="丁目"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input type="text" hidden v-model="areaDetail" />
                  <div
                    class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                  >
                    <div>
                      <div class="d-flex label align-items-center">
                        <p class="f-maru chome-lb">
                          <span>丁目</span>
                          <span class="requied-cl"> ※必須</span>
                        </p>
                      </div>
                      <div class="focus-div" id="residence">
                        <div
                          class="
                            position-relative
                            d-flex
                            align-items-center
                            button-option
                          "
                          :class="isShowResidences ? 'active' : ''"
                          @click="showOptionResidences"
                        >
                          <div class="w-100 input d-flex align-items-center">
                            {{ areaDetail }}
                          </div>
                        </div>
                        <transition name="slide">
                          <div
                            class="options"
                            v-if="isShowResidences && listArea.length"
                          >
                            <p
                              class="option-residence"
                              v-for="(option, indexOption) in listArea"
                              :key="indexOption"
                              @click="selectOptionArea(option.name)"
                            >
                              {{ option.name }}
                            </p>
                          </div>
                        </transition>
                      </div>
                      <span class="error text-left">{{ errors[0] }}</span>
                    </div>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="番地"
                  rules="required|max:255"
                  v-slot="{ errors }"
                >
                  <div
                    class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                  >
                    <p class="label f-maru">
                      <span>番地</span>
                      <span class="requied-cl"> ※必須</span>
                    </p>
                    <div class="input-option">
                      <input
                        type="text"
                        name=""
                        id=""
                        v-model="street"
                        max="255"
                      />
                    </div>
                    <span class="error text-left">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="マンション、アパート名"
                  rules="max:255"
                  v-slot="{ errors }"
                >
                  <div
                    class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                  >
                    <p class="label f-maru other-address">
                      マンション、アパート名
                    </p>
                    <div class="input-option">
                      <input
                        type="text"
                        name=""
                        id=""
                        v-model="house"
                        max="255"
                      />
                    </div>
                    <span class="error text-left">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <!--                <div class="m-auto text-btn">-->
                <!--                  <p class="warning warning-top text-left mb-0">-->
                <!--                    都道府県と、お相手との大まかな距離のみがプロフィール上で表示されます。-->
                <!--                  </p>-->
                <!--                </div>-->
                <div class="d-flex justify-content-center btn-button">
                  <button type="submit" :disabled="invalid" class="w-100">
                    <div
                      class="
                        position-relative
                        d-flex
                        align-items-center
                        button-back
                      "
                    >
                      <div
                        class="text-center posision-absolute w-100 text-center"
                      >
                        次へ
                      </div>
                      <img
                        src="@/assets/image/icon-next.svg"
                        class="icon"
                        alt=""
                      />
                    </div>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProcessBarProfile from "@/components/ProcessBarProfile";
export default {
  name: "TutorialBasicInfoMen",
  data() {
    return {
      isShowPrefectures: false,
      isShowResidences: false,
      residence: null,
      prefecture: null,
      residence_id: null,
      prefecture_id: null,
      error_residence: null,
      error_prefecture: null,
      error_postCode: null,
      residencesState: [],
      postCode: null,
      listArea: [],
      street: null,
      house: null,
      area: null,
      areaDetail: null,
      postCodeNumber: null,
      error_area: null,
      postCodeOld: null,
      error_street: null,
      error_house: null,
      error_area_detail: null,
      year: null,
      month: null,
      day: null,
      date: null,
      showDay: false,
      showMonth: false,
      text: [
        {
          name: "年",
          value: "",
          options: [],
          isShow: false,
          error: null
        },
        {
          name: "月",
          value: "",
          options: [],
          isShow: false,
          error: null
        },
        {
          name: "日",
          value: "",
          options: [],
          isShow: false,
          error: null
        }
      ],
      phone: "",
      phoneNumber: null,
      checkNumberThree: 0,
      checkNumberSeven: 0,
      checkedAge: false,
      checkedLaw: false,
      law: false,
      nickname: "",
      lastNameKanji: "",
      firstNameKanji: "",
      lastNameKana: "",
      firstNameKana: "",
      avgScore: null,
      bestScore: null,
      select: [
        {
          name: "ベストスコア",
          value: "",
          options: [],
          isShow: false,
          error: null,
          class: "point_1"
        },
        {
          name: "アベレージスコア ",
          value: "",
          options: [],
          isShow: false,
          error: null,
          class: "point_2"
        }
      ],
      processStep: 1
    };
  },
  components: {
    "process-bar-profile": ProcessBarProfile
  },
  created() {
    var d = new Date();
    let y = d.getFullYear() - 20;
    for (let i = y; i >= y - 80; i--) {
      this.text[0].options.push(i);
    }

    for (let i = 60; i <= 160; i++) {
      this.select[0].options.push(i);
    }
    this.select[0].options.push("160以上");
    for (let i = 60; i <= 180; i += 5) {
      this.select[1].options.push(i);
    }
    this.select[1].options.push("180以上");
    let layout = document.getElementById("layout-default");
    layout.style.backgroundImage =
      "url(" + require("@/assets/image/background/bgr-pc.png") + ")";
  },
  destroyed() {
    let layout = document.getElementById("layout-default");
    layout.style.backgroundImage =
      "url(" + require("@/assets/image/background/order_list_bg.png") + ")";
  },
  computed: {
    ...mapGetters({
      auth: "auth/user"
    })
  },
  props: {
    prefectures: Array,
    residences: Array,
    tabindex: {
      type: Number,
      require: null,
      default: 0
    },
    propsSex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    date: function() {
      if (this.year != null && this.month != null && this.day != null) {
        this.$emit("date", this.date);
      }
    },
    nickname: function() {
      this.$emit("getNickname", this.nickname);
    },
    lastNameKana: function() {
      this.checkFontKana(
        "fontLastNameKana",
        "sizeLastNameKana",
        this.lastNameKana
      );
    },
    firstNameKana: function() {
      this.checkFontKana(
        "fontFirstNameKana",
        "sizeFirstNameKana",
        this.firstNameKana
      );
    }
  },
  methods: {
    handleBlur() {
      this.postCode = this.postCode?.trim();
      if (
        this.postCode.length == 7 &&
        Number.isInteger(Number(this.postCode)) &&
        !this.postCode.includes(" ")
      ) {
        this.postCodeNumber = this.postCode;
        let postCodeStr = String(this.postCode);
        this.postCode =
          postCodeStr.substr(0, 3) + "-" + postCodeStr.substr(3, 4);
      }
      this.phone = this.phone.trim();
      if (
        this.phone.length == 11 &&
        Number.isInteger(Number(this.phone)) &&
        !this.phone.includes(" ")
      ) {
        let phoneStr = String(this.phone);
        this.phone =
          phoneStr.substr(0, 3) +
          "-" +
          phoneStr.substr(3, 4) +
          "-" +
          phoneStr.substr(7, 4);
      }
    },
    check(value) {
      value += "-";
      this.postCode = value;
    },
    checkPostCode() {
      this.prefecture = null;
      this.residence = null;
      this.area = null;
      this.areaDetail = null;
      this.listArea = [];
      if (
        (event.which >= 96 && event.which <= 105) ||
        (event.which >= 48 && event.which <= 57)
      ) {
        if (this.postCode && this.postCode.length == 3) {
          this.postCodeNumber = this.postCode;
          if (
            event.which != 8 &&
            event.which != 46 &&
            !this.postCode.includes(" ")
          ) {
            this.check(this.postCode);
          }
        } else if (
          this.postCode &&
          this.postCode.length > 3 &&
          !this.postCode.includes("-") &&
          !this.postCode.includes(" ")
        ) {
          this.postCodeNumber = this.postCode;
          let postCodeStr = String(this.postCode);
          this.postCode =
            postCodeStr.substr(0, 3) +
            "-" +
            postCodeStr.substr(3, this.postCode.length - 3);
        }
      }
    },
    blur(e) {
      if (!e.target.closest("#prefecture")) {
        this.isShowPrefectures = false;
      }
      if (!e.target.closest("#residence")) {
        this.isShowResidences = false;
      }

      if (!e.target.closest("#年")) {
        this.text[0].isShow = false;
      }
      if (!e.target.closest("#月")) {
        this.text[1].isShow = false;
      }
      if (!e.target.closest("#日")) {
        this.text[2].isShow = false;
      }

      if (!e.target.closest("#best-score")) {
        this.select[0].isShow = false;
      }
      if (!e.target.closest("#avg-score")) {
        this.select[1].isShow = false;
      }
    },
    showOptionResidences() {
      this.isShowResidences = !this.isShowResidences;
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName("option-residence");
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.residence) {
            listOptions[i].style.backgroundColor = "#e5e5e5";
            listOptions[i].style.color = "#000000";
          }
        }
      });
    },
    selectOptionArea(name) {
      this.areaDetail = name;
      this.isShowResidences = !this.isShowResidences;
    },
    fillPrefecture() {
      let postCode = this.postCode;
      if (this.postCode) {
        postCode = this.postCode.replace("-", "");
        if (postCode != this.postCodeOld) {
          this.areaDetail = null;
          this.postCodeOld = postCode;
        }
      }
      const postal_code = require("japan-postal-code");
      const self = this;
      postal_code.get(postCode, async function(address) {
        self.prefecture = address.prefecture;
        self.residence = address.city;
        self.area = address.area;
        const params = {
          prefecture_name: address.prefecture,
          city_name: address.city,
          area_name: address.area
        };

        await self.$store
          .dispatch("common/getListArea", params)
          .then(res => {
            self.listArea = res.data;
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    onSubmit() {
      // point
      if (!this.bestScore) {
        this.select[0].error = this.select[0].name + "は必須項目です";
      }
      if (!this.avgScore) {
        this.select[1].error = this.select[1].name + "は必須項目です";
      }

      //date
      if (this.year == null) {
        this.text[0].error = "年は必須項目です";
      }
      if (this.month == null) {
        this.text[1].error = "月は必須項目です";
      }
      if (this.day == null) {
        this.text[2].error = "日は必須項目です";
      }

      if (
        this.year != null &&
        this.month != null &&
        this.day != null &&
        this.bestScore &&
        this.avgScore &&
        this.checkedAge &&
        this.checkedLaw &&
        this.nickname
      ) {
        const params = {
          prefecture_name: this.prefecture,
          city_name: this.residence,
          area_name: this.areaDetail,
          street_name: this.street,
          house_name: this.house,
          post_code: this.postCode.replace("-", ""),
          nickname: this.nickname,
          bestScore: this.bestScore,
          avgScore: this.avgScore,
          date: this.year + "-" + this.month + "-" + this.day,
          phone: this.phone
        };
        this.$emit("tutorialBasicInfo", params);
      }
    },
    changeNickName(event) {
      this.nickname = event.target.value;
    },
    checkFontKana(refFont, refSize, text) {
      this.checkSize(refFont, refSize, text);
      if (text.length <= 255) {
        for (let i = 0; i < text.length; i++) {
          if (!text[i].match(/[\u30a0-\u30ff]/) && text[i] != " ") {
            this.$refs[refFont].classList.add("error-text-font");
            this.$refs[refSize].classList.remove("error-text-size");
            break;
          } else {
            this.$refs[refFont].classList.remove("error-text-font");
            this.$refs[refSize].classList.remove("error-text-size");
          }
        }
      }
    },
    checkSize(refFont, refSize, text) {
      if (text.length > 255) {
        this.$refs[refFont].classList.remove("error-text-font");
        this.$refs[refSize].classList.add("error-text-size");
      } else if (text.length == 0) {
        this.$refs[refFont].classList.remove("error-text-font");
        this.$refs[refSize].classList.remove("error-text-size");
      }
    },
    handleBlurFirstNameKana() {
      this.firstNameKana = this.firstNameKana.trim();
    },
    handleBlurFirstNameKanji() {
      this.firstNameKanji = this.firstNameKanji.trim();
    },
    handleBlurLastNameKana() {
      this.lastNameKana = this.lastNameKana.trim();
    },
    handleBlurLastNameKanji() {
      this.lastNameKanji = this.lastNameKanji.trim();
    },
    handleBlurNickname() {
      this.nickname = this.nickname.trim();
    },

    showOptionPoint(index, name) {
      this.select[index].isShow = !this.select[index].isShow;
      if (index == 0) {
        this.select[1].isShow = false;
      } else {
        this.select[0].isShow = false;
      }
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName(name);
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.select[index].value) {
            listOptions[i].style.backgroundColor = "#e5e5e5";
            listOptions[i].style.color = "#000000";
          }
        }
      });
    },
    selectOptionPoint(index, name) {
      this.select[index].value = name;
      this.select[index].error = null;
      if (this.select[index].name == "ベストスコア") {
        this.bestScore = name;
        this.$emit("bestScore", name);
      } else {
        this.avgScore = name;
        this.$emit("avgScore", name);
      }
      this.select[index].isShow = false;
    },

    getDay(year, month, option) {
      if (this.text[1].value) {
        this.showDay = true;
      } else {
        this.showDay = false;
        this.text[2].value = null;
        this.day = null;
      }
      this.text[2].options = [];
      let day = new Date(year, month, 0).getDate();
      let day_now = new Date().getDate();
      let d = day;
      if (
        option == this.text[1].options.length - 1 &&
        Number(year) == Number(new Date().getFullYear()) - 20
      ) {
        d = day_now;
      }
      if (
        option == 0 &&
        Number(year) == Number(new Date().getFullYear()) - 20 &&
        Number(this.text[2].value) >= d
      ) {
        this.text[2].value = null;
      }
      for (let i = 1; i <= d; i++) {
        if (i < 10) {
          i = "0" + i;
        }
        this.text[2].options.push(i);
      }
    },
    showOption(index, name) {
      this.text[index].isShow = !this.text[index].isShow;
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName(name);
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.text[index].value) {
            listOptions[i].style.backgroundColor = "#e5e5e5";
            listOptions[i].style.color = "#000000";
          }
        }
      });
    },
    getMonth(index) {
      (this.showMonth = true), (this.text[1].options = []);
      var d = new Date();
      let day_now = new Date().getDate();
      var m = d.getMonth();
      if (index != 0) {
        m = 11;
      }
      if (index == 0 && Number(this.text[1].value) > m) {
        this.text[1].value = null;
        this.month = null;
      }
      if (
        index == 0 &&
        Number(this.text[1].value) == m + 1 &&
        Number(this.text[2].value) > Number(day_now)
      ) {
        this.text[2].value = null;
        this.day = null;
      }
      for (let i = 1; i <= m + 1; i++) {
        if (i < 10) {
          i = "0" + i;
        }
        this.text[1].options.push(i);
      }
      this.getDay(this.text[0].value, this.text[1].value, 0);
    },
    selectOption(index, name, option) {
      this.text[index].value = name;
      this.text[index].error = null;
      if (this.text[index].name == "年") {
        this.year = name;
        this.getMonth(option);
        if (this.day > this.text[2].options[this.text[2].options.length - 1]) {
          this.text[2].value = "";
          this.day = null;
        }
      } else if (this.text[index].name == "月") {
        this.month = name;
        this.getDay(this.year, this.month, option);
        if (this.day > this.text[2].options[this.text[2].options.length - 1]) {
          this.text[2].value = "";
          this.day = null;
        }
      } else {
        this.day = name;
      }
      this.text[index].isShow = false;
      if (this.month != null && this.day != null && this.year != null) {
        let date = this.year + "-" + this.month + "-" + this.day;
        this.$emit("getDate", date);
      }
    },
    checkPhoneNumber(value) {
      value += "-";
      this.phone = value;
    },
    checkPhone() {
      if (
        (event.which >= 96 && event.which <= 105) ||
        (event.which >= 48 && event.which <= 57)
      ) {
        if (this.phone.length == 3) {
          this.phoneNumber = this.phone;
          if (
            event.which != 8 &&
            event.which != 46 &&
            !this.phone.includes(" ")
          ) {
            this.checkPhoneNumber(this.phoneNumber);
          }
        } else if (this.phone.length == 8 && !this.phone.includes(" ")) {
          this.phoneNumber = this.phone;
          if (event.which != 8 && event.which != 46) {
            this.checkPhoneNumber(this.phoneNumber);
          }
        } else if (
          this.phone.length > 3 &&
          this.phone.length < 9 &&
          !this.phone.includes("-") &&
          !this.phone.includes(" ")
        ) {
          let phoneStr = String(this.phone);
          this.phone =
            phoneStr.substr(0, 3) +
            "-" +
            phoneStr.substr(3, this.phone.length - 3);
        }
      }
    },
    showLaw() {
      if (this.propsSex == 1) {
        this.$router.push({
          name: "MenTermOfUse",
          query: {
            policy: "success"
          }
        });
        return;
      }
      this.$router.push({
        name: "CastTermOfUse",
        query: {
          policy: "success"
        }
      });
    },
    nextPolicy() {
      if (this.propsSex == 1) {
        this.$router.push({
          name: "MenPolicy",
          query: {
            policy: "success"
          }
        });
        return;
      }
      this.$router.push({
        name: "CastPolicy",
        query: {
          policy: "success"
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/tutorialBasicInfo";
@import "@/assets/scss/_commons";
@import "@/assets/scss/_fontFamily";
@import "@/assets/scss/_variables";
#bacsic-info-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-image: url("~@/assets/image/icon_profile/profile-bgr-sm.png");
  }
  position: relative;
  z-index: 1000;
  height: calc(100%);
  width: 100vw;
  @media screen and (min-width: 1079px) {
    width: 1080px;
  }
  .card {
    //margin-left: 120px;
    //margin-right: 120px;
    background-color: #f5f5f5;
  }
  .lb-address {
    position: relative;
    bottom: -18px;
  }
  .text-address {
    font-weight: 600;
  }
  .tutorial-notification__div {
    border-radius: 0px;
  }
  .slide-enter,
  .slide-leave-to {
    transform: scaleY(0);
  }

  .process-bar-info {
    margin-top: 20px !important;
  }

  .focus-div {
    outline: none;
    box-shadow: none;
  }
  .btn-button {
    //margin-top: 50px;
  }
  .date-warning {
    margin-left: 5px;
  }
  .requied-cl {
    color: #e60013;
    font-size: 14px;
    position: relative;
    top: -3px;
  }
  .age-acc {
    margin-bottom: 30px;
    margin-top: -10px;
  }
  .button-post-code {
    font-size: 15px;
    border: 1px solid #464d77;
    background-color: #464d77;
    color: #ffffff;
    border-radius: 6.35px;
    box-shadow: none;
    min-width: 82px;
    margin-bottom: 0;
    cursor: pointer;
    @media screen and (min-width: 1079px) {
      min-width: 120px;
      font-size: 23px;
    }
  }
  .warning {
    color: $red_color_error_tutorial;
    font-size: 10px;
    //margin-top: 5px;
    @media screen and (min-width: 1079px) {
      font-size: 14px;
      //margin-top: 16px;
    }
  }
  .text-btn {
    max-width: 334px;
  }
  .box-address {
    display: flex;
    justify-content: space-between;
    .province {
      width: 35%;
    }
    .district {
      width: 62%;
    }
  }
  @media screen and (min-width: 1080px) {
    .btn-button {
      position: unset;
      top: 0;
      z-index: -10000;
      transform: unset;
      margin-top: -60px;
    }
    .text-btn {
      max-width: 537px;
    }
    .point_2 {
      margin-top: -15px;
    }
    .box-date {
      margin-top: 19px;
    }
    .num-address {
      margin-top: 1px;
    }
    .box-address {
      margin-top: -6px;
    }
    .date-warning {
      margin-top: -25px !important;
    }
    .only-show-profile-text {
      margin-top: 0 !important;
      p {
        font-size: 14px;
        padding-top: 3px;
      }
    }
    .other-address {
      margin-bottom: 7px;
    }
  }
  @media screen and (max-width: 1079px) {
    .tutorial-welcome {
      .text--blue {
        font-size: 15px;
      }
    }
    .btn-button {
      margin-top: 20px;
      //margin-left: 10px;
      //margin-right: 10px;
    }
    .only-show-profile-text {
      margin-top: 0 !important;
      p {
        font-size: 12px;
        padding-top: 5px;
      }
      img {
        width: 15px;
      }
    }

    .text-address {
      margin-top: 26px;
    }
  }
  .suggest-year {
    padding-left: 2px;
  }
  .text-year {
    font-size: 23px;
    color: #adabab;
  }

  .underline {
    text-decoration: underline;
  }

  .date-padding {
    padding-right: 2px !important;
    padding: 0 3px !important;
  }

  .basic-info-acc {
    margin-top: 20px;
    font-weight: 600;
    p {
      font-size: 23px;
    }
  }

  .tutorial-welcome {
    .disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    .btn-back {
      position: absolute;
      top: 10px;
      left: 19px;
      cursor: pointer;
    }
    .image-welcome {
      height: 261px;
      background-image: url("~@/assets/image/icon_mypage/welcome-tutorial-pc.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .content {
      padding: 23px 26px 24px 26px;
      color: #1a1311;
      .logo-prego {
        width: 140px;
        height: 35px;
        float: left;
      }
      .welcome-text {
        margin-left: 6px;
        font-size: 15px;
        line-height: 15px;
      }
      &__law {
        margin-top: 34px;
        &--text {
          font-size: 14px;
          text-align: left;
          margin-bottom: 17px;
          &-last {
            font-size: 18px;
          }
          &.--bottom {
            margin-top: 5px;
            margin-bottom: 54px;
          }
        }
      }
      .top {
        margin-bottom: 32px;
        margin-top: 34px;
      }
      &--checkbox {
        .checkbox {
          width: 18px;
          border-radius: 4px;
          height: 18px;
          margin-right: 13px;
          border: 1px solid #b9b9b9;
          border-top: none;
          border-left: none;
          color: $white;
          background-color: #ffffff;
          &.active {
            background-color: #464d77;
          }
          &-law {
            margin-bottom: 0;
            margin-right: 12px;
          }
        }
        .text {
          font-size: 13px;
          line-height: 13px;
          bottom: 32px;
          left: 30px;
          &--bottom {
            bottom: 0;
          }
          &--blue {
            color: #75c4cc;
            font-size: 15px;
            line-height: 15px;
            &.text-law {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .basic-info-acc {
      p {
        font-size: 15px;
      }
    }

    .card {
      margin: 30px 15px;
      border-radius: 5px;
    }

    .tutorial-notification__div {
      border-radius: 5px;
    }

    .text-year {
      font-size: 14px;
    }

    .requied-cl {
      font-size: 9px;
      top: -1px;
    }

    .form {
      padding-right: 10px;
      padding-left: 10px;
    }

    .box-address {
      margin-top: -7px;
    }

    .num-address {
      margin-top: -4px;
    }

    .point_1 {
      margin-top: 22px;
      p {
        margin-bottom: -3px;
      }
    }

    .point_2 {
      margin-top: 21px;
      p {
        margin-bottom: -4px;
      }
    }
    .date-warning {
      margin-top: -18px !important;
    }
    .only-show-profile-text {
      p {
        font-size: 10px;
        padding-top: 6px;
      }
    }
    .date-padding {
      p {
        margin-bottom: -5px;
      }
    }
    .chome-lb {
      margin-bottom: -3px;
    }

    .other-address {
      margin-bottom: 2px;
    }
  }
  @media screen and (min-width: 1079px) {
    .tutorial-welcome {
      .content {
        padding: 10px 26px 25px 45px;
        &__law {
          margin-top: 27px;
          &--text {
            font-size: 23px;
            line-height: 23px;
            text-align: left;
            margin-bottom: 24px;
            &-last {
              font-size: 27px;
            }
            &.--bottom {
              margin-bottom: 37px;
            }
          }
        }
        .top {
          margin-bottom: 24px;
        }
        &--checkbox {
          .checkbox {
            width: 27px;
            height: 27px;
            margin-right: 21px;
            font-size: 20px;
            .b-icon.bi {
              width: 1.5rem;
              height: 1.5rem;
            }
            &-law {
              margin-bottom: 0;
            }
          }
          .text {
            font-size: 20px;
            line-height: 21px;
            bottom: 22px;
            left: 50px;
            &--bottom {
              bottom: -1px;
            }
            &--blue {
              font-size: 20px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
