<template>
  <div id="type" @click="blur">
    <AppContainer :useBackground="true">
      <div class="type-tutorial">
        <div class="type__background">
          <div class="type type-pc">
            <div class="text-left" @click="$emit('back-tutorial')">
              <img
                class="btn-back"
                :src="require('@/assets/image/arrow-back.svg')"
                alt=""
              />
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitTutorial)">
                <p class="title title-pc f-maru">
                  あなたのタイプを教えてください！
                </p>
                <div class="type__div text-left">
                  <p class="type__p p-pc f-maru">タイプ</p>
                  <SelectOption
                    :propsOptions="histories"
                    :propsStyle="'medium'"
                    :props-rules="{ required: false }"
                    @emitOption="chooseOption($event, 'appearance')"
                    v-model="form.golfHistory"
                    :propsName="'タイプ'"
                    @showOption="showOption()"
                    :propsId="'type-cast'"
                    :propsShow="show"
                    :propsImperative="true"
                  />
                </div>
                <button type="submit">
                  <div
                    class="
                      position-relative
                      d-flex
                      align-items-center
                      button-back
                    "
                  >
                    <div
                      class="
                        text-center
                        posision-absolute
                        w-100
                        text-center
                        f-maru
                      "
                    >
                      次へ
                    </div>
                    <img
                      src="@/assets/image/icon-next.svg"
                      class="icon"
                      alt=""
                    />
                  </div>
                </button>
              </form>
            </ValidationObserver>
            <div class="skip-padding f-maru" @click="nextTutorial">
              スキップ
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import SelectOption from "@/components/SelectOption";

export default {
  name: "HistoryTutorial",
  components: {
    SelectOption: SelectOption
  },
  data() {
    return {
      show: false,
      histories: [],
      form: {
        appearance: ""
      }
    };
  },
  props: {
    propsOptions: {
      type: Array,
      default: null
    }
  },
  created() {
    this.histories = this.propsOptions.map(function(option) {
      return {
        name: option.name,
        value: option.appearance_id
      };
    });
  },
  methods: {
    chooseOption(data, name) {
      this.form[name] = data;
      this.reflectData(name);
      this.show = false;
    },
    showOption() {
      this.show = !this.show;
    },
    blur(e) {
      if (!e.target.closest("#type-cast")) {
        this.show = false;
      }
    },
    submitTutorial() {
      this.$emit("submitType", this.form);
    },

    nextTutorial() {
      this.$emit("nextType");
    },

    reflectData(data) {
      this.$emit("emitReflect", {
        name: data,
        value: this.form[data]
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/cast/tutorial/TutorialType.scss";
@import "~@/assets/scss/_fontFamily.scss";
#type {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
}
</style>
