<template>
  <div id="alone" @click="blur">
    <AppContainer :useBackground="true">
      <div class="text-center d-flex justify-content-center alone">
        <div class="cast-profile-edit-night">
          <div class="text-left" @click="$emit('back-tutorial')">
            <img
              class="btn-back"
              :src="require('@/assets/image/arrow-back.svg')"
              alt=""
            />
          </div>
          <label for="" class="text-center f-maru">
            お一人でのラウンド参加は可能かどうか 教えてください！
          </label>
          <div class="position-relative focus-div">
            <div
              class="cast-profile-edit-night__input"
              @click="showListAlone"
              id="alone-cast"
            >
              <div
                class="cast-profile-edit-night__input-label text-left f-maru"
              >
                一人参加
              </div>
              <div
                class="
                  cast-profile-edit-night__input-select
                  position-relative
                  m-auto
                "
                :class="isShowListAlone ? 'active' : ''"
              >
                <div
                  type="text"
                  class="
                    f-maru
                    input
                    d-flex
                    justify-content-start
                    align-items-center
                  "
                  readonly
                >
                  {{ aloneSelected.text }}
                </div>
              </div>
            </div>
            <transition name="slide">
              <ul
                class="
                  cast-profile-edit-night__listAlone
                  text-left
                  position-absolute
                "
                v-if="isShowListAlone"
              >
                <li
                  class="cast-profile-edit-night__listAlone-item f-maru"
                  v-for="(item, index) in listAlone"
                  :key="index"
                  @click="selectOptionAlone(item)"
                >
                  {{ item.text }}
                </li>
              </ul>
            </transition>
          </div>
          <div
            class="cast-profile-edit-night__next position-relative f-maru"
            @click="nextTutorial"
          >
            次へ
            <img
              src="@/assets/image/arrow-right-submit.svg"
              class="icon position-absolute"
              alt=""
            />
          </div>
          <div
            class="cast-profile-edit-night__skip f-maru"
            @click="nextTutorial"
          >
            スキップ
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
export default {
  name: "ProfileEditScreenDetailsEight",
  data() {
    return {
      aloneSelected: {
        text: "",
        value: 0
      },
      isShowListAlone: false,
      listAlone: [
        {
          text: "1人参加OK",
          value: 1
        },
        {
          text: "1人参加NG",
          value: 0
        }
      ]
    };
  },
  props: {
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  watch: {
    aloneSelected: function() {
      this.$emit("showAlone", this.aloneSelected);
    }
  },
  methods: {
    blur(e) {
      if (!e.target.closest("#alone-cast")) {
        this.isShowListAlone = false;
      }
    },
    nextTutorial() {
      this.$emit("showAlone", this.aloneSelected);
      this.$emit("tutorialAlone");
    },
    showListAlone() {
      this.isShowListAlone = !this.isShowListAlone;
      this.$nextTick(() => {
        let listOptions = document.querySelectorAll(
          ".cast-profile-edit-night__listAlone-item"
        );
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.aloneSelected.text) {
            listOptions[i].style.backgroundColor = "#e5e5e5";
            listOptions[i].style.color = "#000000";
            listOptions[i].scrollIntoView(false);
          }
        }
      });
    },
    selectOptionAlone(value) {
      if (this.aloneSelected == value) {
        this.aloneSelected = {
          text: "",
          value: 0
        };
        this.isShowListAlone = !this.isShowListAlone;
        return;
      }
      this.aloneSelected = value;
      this.isShowListAlone = !this.isShowListAlone;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cast/tutorial/tutorialParticipationAlone.scss";
#alone {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
  .slide-enter,
  .slide-leave-to {
    transform: scaleY(0);
  }
  .focus-div {
    box-shadow: none;
    outline: none;
  }
}
</style>
