<template>
  <div id="other" @click="blur">
    <AppContainer :useBackground="true">
      <div class="text-center d-flex justify-content-center">
        <div class="cast-profile-other">
          <div class="text-left" @click="$emit('back-tutorial')">
            <img
              class="btn-back"
              :src="require('@/assets/image/arrow-back.svg')"
              alt=""
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(nextTutorial)">
              <label for="" class="text-center f-maru">
                あなたのゴルフスタイル・ほかに話せる言語を教えてください！
              </label>
              <ValidationProvider name="スタイル" rules="" v-slot="{ errors }">
                <div class="position-relative focus-div">
                  <div class="cast-profile-other__input">
                    <div
                      class="cast-profile-other__input-label text-left f-maru"
                    >
                      その他
                    </div>
                    <div
                      class="
                        cast-profile-other__input-select
                        position-relative
                        f-maru
                        d-flex
                        justify-content-start
                        align-items-center
                        m-auto
                      "
                      :class="isShowListOther ? 'active' : ''"
                      @click="showListOther"
                    >
                      <div
                        class="cast-profile-other__input-select__div-name input"
                      >
                        {{ otherPreferencesName }}
                      </div>
                    </div>
                    <p class="text-note mb-0 text-left">複数選択可</p>
                  </div>
                  <transition name="slide">
                    <ul
                      class="cast-profile-other__listOther text-left"
                      v-if="isShowListOther"
                    >
                      <li
                        class="cast-profile-other__listOther-item f-maru"
                        v-for="(item, index) in listOther"
                        :key="index"
                        :class="
                          otherSelected.indexOf(item.other_preference_id) > -1
                            ? 'actived'
                            : ''
                        "
                        @click="selectOptionOther(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </transition>
                </div>
                <span v-if="errors[0]" class="error">{{ errors[0] }}</span>
              </ValidationProvider>
              <div
                class="
                  cast-profile-other__next
                  position-relative
                  d-flex
                  justify-content-center
                  align-items-center
                  f-maru
                "
                @click="nextTutorial"
              >
                次へ
                <img
                  src="@/assets/image/arrow-right-submit.svg"
                  class="icon position-absolute"
                  alt=""
                />
              </div>
            </form>
          </ValidationObserver>
          <div class="cast-profile-other__skip f-maru" @click="nextTutorial">
            スキップ
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
export default {
  name: "ProfileEditScreenDetailsEight",
  data() {
    return {
      otherSelected: [],
      otherName: [],
      otherPreferencesName: null,
      isShowListOther: false,
      listOther: []
    };
  },
  props: {
    otherPreferences: Array,
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  watch: {
    otherSelected: function() {
      this.otherName = [];
      for (let j = 0; j < this.otherSelected.length; j++) {
        for (let i = 0; i < this.otherPreferences.length; i++) {
          if (
            this.otherSelected[j] ==
            this.otherPreferences[i].other_preference_id
          ) {
            this.otherName.push(this.otherPreferences[i].name);
          }
        }
      }
      this.otherPreferencesName = this.otherName.join(", ");
    }
  },
  methods: {
    blur(e) {
      if (!e.target.closest(".focus-div")) {
        this.isShowListOther = false;
      }
    },
    focus() {
      var foo = document.getElementById("input-other");
      foo.setSelectionRange(foo.value.length, foo.value.length);
    },
    nextTutorial() {
      this.$emit(
        "tutorialOther",
        this.otherSelected.length > 0 ? this.otherSelected.toString() : null
      );
    },
    showListOther() {
      this.listOther = this.otherPreferences;
      this.isShowListOther = !this.isShowListOther;
    },
    selectOptionOther(value) {
      for (let i = 0; i < this.otherSelected.length; i++) {
        if (this.otherSelected[i] == value.other_preference_id) {
          const k = this.otherSelected.indexOf(this.otherSelected[i]);
          this.otherSelected.splice(k, 1);
          return;
        }
      }
      this.otherSelected.push(value.other_preference_id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cast/tutorial/tutorialOther.scss";
#other {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
  overflow-x: hidden;
  .slide-enter,
  .slide-leave-to {
    transform: scaleY(0);
  }
  .focus-div {
    outline: none;
    box-shadow: none;
  }
  .text-note {
    font-size: 10px;
    color: #acabab;
    margin-left: 10px;
  }
  @media screen and (min-width: 1200px) {
    .text-note {
      font-size: 16px;
      margin-left: 12px;
    }
  }
}
</style>
